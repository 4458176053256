import React from 'react'
import classNames from 'classnames'

import { EloPaymentName } from '@elo-ui/components/elo-payment-name'
import { EloPriceDisplay } from '@elo-ui/components/elo-price-display'
import { EloPaymentTime } from '@elo-ui/components/elo-payment-time'

import './elo-payment-item.scss'

interface Props {
  variant: 'trial' | 'first' | 'default'
  paymentName: string
  size?: 'small' | 'medium' | 'large'
  price: number | string
  oldPrice?: number | string
  supportiveText?: string
  netPrice?: string
  from?: string
  fromLabel?: string
  on?: string
  onLabel?: string
  to?: string
  toLabel?: string
  firstItem?: boolean
  lastItem?: boolean
  paymentColor?: string
}

export const EloPaymentItem: React.FC<Props> = (props) => {
  const {
    variant = 'default',
    paymentName,
    price,
    oldPrice,
    netPrice,
    size = 'small',
    from,
    fromLabel,
    on,
    onLabel,
    to,
    toLabel,
    supportiveText,
    firstItem = false,
    lastItem = false,
    paymentColor,
  } = props

  const containerClasses = classNames('elo-payment-item__container', `elo-payment-item__container--${variant}`, {
    'elo-payment-item__container--front-border': firstItem,
    'elo-payment-item__container--back-border': lastItem,
  })

  return (
    <div
      className='elo-payment-item'
      style={
        {
          '--elo-payment-item-background-color': paymentColor,
        } as React.CSSProperties
      }
    >
      <div className={containerClasses}>
        <div className='elo-payment-item__container--flex'>
          <EloPaymentName paymentName={paymentName} />

          <div className='elo-payment-item__container--mobile-time'>
            <EloPaymentTime from={from} fromLabel={fromLabel} on={on} onLabel={onLabel} to={to} toLabel={toLabel} />
          </div>
        </div>
        <EloPriceDisplay
          price={price}
          oldPrice={oldPrice}
          size={size}
          supportiveText={supportiveText}
          netPrice={netPrice}
        />
      </div>
      <div className='elo-payment-item__container--desktop-time'>
        <EloPaymentTime from={from} fromLabel={fromLabel} on={on} onLabel={onLabel} to={to} toLabel={toLabel} />
      </div>
    </div>
  )
}
