import { FROALA_TAGS } from '../constants/froala.constants'

export const checkIsHtml = (text = '') =>
  FROALA_TAGS.some((tag) => {
    const regexp = new RegExp(`<${tag}`, 'i')
    return regexp.test(text)
  })

export const parseTextFromHtml = (text = '') => {
  let textCopy = text

  FROALA_TAGS.forEach((tag) => {
    const openTagRegexp = new RegExp(`<${tag}>`, 'g')
    const openTagWithAttributesRegexp = new RegExp(`<${tag}[^>]+>`, 'g')
    const closeTagRegexp = new RegExp(`</${tag}>`, 'g')

    textCopy = textCopy.replace(openTagRegexp, '').replace(openTagWithAttributesRegexp, '').replace(closeTagRegexp, '')
  })

  return textCopy
}
