import React from 'react'
import { Nullable } from 'types/helpers'

import { TICKET_FORM_WITHOUT_DATES } from '@elo-kit/constants/products.constants'
import { formatDateTime } from '@elo-kit/utils/dateTime.utils'

interface TicketDate {
  eventForm?: string
  startDate?: Date | string
  timeZoneName?: Nullable<string>
  endDate?: Date | string
  additionalText?: string
}
interface Props {
  ticketDate?: TicketDate
  withAdditionalText?: boolean
}
/**
 * Formatted Ticket Dates - show ticket dates in unified way
 */
export const FormattedTicketDates: React.FC<Props> = ({ ticketDate = {}, withAdditionalText }): any => {
  const { eventForm, startDate = '', timeZoneName, endDate = '', additionalText } = ticketDate

  if ((!startDate && !endDate) || eventForm === TICKET_FORM_WITHOUT_DATES) {
    return withAdditionalText && additionalText ? additionalText : null
  }

  return (
    <>
      {formatDateTime(String(startDate), null, timeZoneName)}
      {endDate && (
        <>
          <span>&nbsp;-&nbsp;</span>
          {formatDateTime(String(endDate), null, timeZoneName)}
          {additionalText}
        </>
      )}
      {timeZoneName && (
        <span>
          &nbsp;
          {timeZoneName}
        </span>
      )}
    </>
  )
}

FormattedTicketDates.displayName = 'FormattedTicketDates'
