import React from 'react'

import './elo-payment-time.scss'

interface Props {
  from?: string
  fromLabel?: string
  on?: string
  onLabel?: string
  to?: string
  toLabel?: string
}

export const EloPaymentTime: React.FC<Props> = (props) => {
  const { from, fromLabel, on, onLabel, to, toLabel } = props

  return (
    <div className='elo-payment-time'>
      {from && !to && (
        <div className='elo-payment-time__date'>
          {fromLabel} <span>{from}</span>
        </div>
      )}
      {on && (
        <div className='elo-payment-time__date'>
          {onLabel} <span>{on}</span>
        </div>
      )}
      {from && to && (
        <div className='elo-payment-time__date'>
          {fromLabel} <span>{from}</span> {toLabel} <span>{to}</span>
        </div>
      )}
    </div>
  )
}
