import React from 'react'
import classNames from 'classnames'

import { EloTooltip } from '@elo-ui/components/elo-tooltip'
import { EloInfoIcon, EloRadiobuttonSelectedIcon, EloRadiobuttonUnselectedIcon } from '@elo-ui/components/icons/regular'
import { TEST_IDS, TooltipProps } from '@elo-ui/types'

import './elo-radio-button.scss'

interface Props {
  disabled?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onClick?: React.MouseEventHandler<HTMLInputElement>
  className?: string
  dataTestId?: string
  ariaLabel?: string
  checked?: boolean
  name?: string
  tooltipOptions?: TooltipProps
  size?: 'small' | 'extra-small'
  children?: React.ReactNode
  borderColor?: string
  hidden?: boolean
  id?: string
}

export const EloRadioButton: React.FC<Props> = ({
  children,
  disabled = false,
  onChange = null,
  className,
  ariaLabel = 'Radio',
  checked = false,
  dataTestId = TEST_IDS.radioButton,
  name = '',
  tooltipOptions,
  onClick = () => {},
  size = 'small',
  borderColor,
  hidden = false,
  id = '',
}) => {
  const classes = classNames('elo-radio-button', className)

  return (
    <label
      className={classes}
      style={
        {
          '--elo-radio-button-box-highlight-border-color': borderColor,
          '--elo-radio-button-box-checked-color': borderColor,
        } as React.CSSProperties
      }
    >
      {!hidden && (
        <>
          <input
            id={id}
            type='radio'
            className='elo-radio-button__input'
            disabled={disabled}
            tabIndex={disabled ? -1 : 0}
            aria-disabled={disabled}
            aria-label={ariaLabel}
            checked={checked}
            onChange={onChange}
            onClick={onClick}
            data-testid={dataTestId}
            name={name}
          />
          <span className={classNames('elo-radio-button__box', `elo-radio-button__box--${size}`)}>
            {checked ? (
              <EloRadiobuttonSelectedIcon size={size === 'small' ? 20 : 16} />
            ) : (
              <EloRadiobuttonUnselectedIcon size={size === 'small' ? 20 : 16} />
            )}
          </span>
        </>
      )}
      <span className='elo-radio-button__label'>{children}</span>
      {tooltipOptions && (
        <span className='elo-radio-button__label-tooltip'>
          <EloTooltip {...tooltipOptions}>
            {tooltipOptions.children ? tooltipOptions.children : <EloInfoIcon size={16} />}
          </EloTooltip>
        </span>
      )}
    </label>
  )
}
