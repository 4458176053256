import { COUPON_PRODUCTS, Coupon } from '../constants/coupons.constants'

export const checkIsCouponApplied = (coupon: Coupon, pricingPlanId: number, ticketId: number) => {
  const { couponsProducts = [], applyForm } = coupon?.data || {}
  const { pricingPlanId: singleProductPricingPlanId, ticketId: firstProductTicketId } = couponsProducts[0] || {}

  if (ticketId && applyForm === COUPON_PRODUCTS.single) {
    const productIdsAreEqual = ticketId === firstProductTicketId
    return singleProductPricingPlanId
      ? coupon?.applied && singleProductPricingPlanId === pricingPlanId && productIdsAreEqual
      : coupon?.applied && productIdsAreEqual
  }

  const singleCouponApplied =
    applyForm === COUPON_PRODUCTS.single &&
    (singleProductPricingPlanId ? singleProductPricingPlanId === pricingPlanId : true)
  const multipleCouponApplied = applyForm === COUPON_PRODUCTS.multiple
  const allCouponApplied = applyForm === COUPON_PRODUCTS.all

  return coupon?.applied && (allCouponApplied || multipleCouponApplied || singleCouponApplied)
}
