import React from 'react'

import './elo-payment-name.scss'

interface Props {
  paymentName: string
}

export const EloPaymentName: React.FC<Props> = (props) => {
  const { paymentName } = props

  return <div className='elo-payment-name'>{paymentName}</div>
}
