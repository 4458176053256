import React, { useCallback, MouseEvent } from 'react'
import classNames from 'classnames'
import { TEST_IDS, LinkProps } from '../../types'

import './elo-link.scss'

export const EloLink: React.FC<LinkProps> = ({
  onClick = null,
  as: Component = 'a',
  external = false,
  disabled = false,
  children,
  className,
  ariaLabel = 'Link',
  highlight,
  light,
  dataTestId = TEST_IDS.link,
  ...restProps
}) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (disabled || !onClick) return

      return onClick(e)
    },
    [disabled, onClick]
  )
  const classes = classNames(className, 'elo-link', {
    'elo-link--disabled': disabled,
    'elo-link--highlight': highlight,
    'elo-link--light': light,
  })
  const externalProps = external ? { target: '_blank', rel: 'noopener noreferrer' } : null

  return (
    <Component
      onClick={handleClick}
      className={classes}
      tabIndex={disabled ? -1 : 0}
      aria-disabled={disabled}
      aria-label={ariaLabel}
      data-testid={dataTestId}
      {...externalProps}
      {...restProps}
    >
      {children}
    </Component>
  )
}
