import React, { useRef } from 'react'
import classNames from 'classnames'

import { EloPaymentItem } from '@elo-ui/components/elo-payment-item'
import { EloLink } from '@elo-ui/components/elo-link'

import { useOverflowed } from '@elo-ui/hooks/use-overflowed'

import './elo-payment-plan.scss'

interface Props {
  paymentItems: {
    variant: 'trial' | 'first' | 'default'
    paymentName: string
    price: number | string
    oldPrice?: number | string
    netPrice?: string
    from?: string
    fromLabel?: string
    on?: string
    onLabel?: string
    to?: string
    toLabel?: string
    supportiveText?: string
    thinItem?: boolean
  }[]
  paymentColor?: string
  showFullPaymentPlanModal?: boolean
  toggleFullPaymentPlanModal?: () => void
}

export const EloPaymentPlan: React.FC<Props> = (props) => {
  const { paymentItems, paymentColor, showFullPaymentPlanModal, toggleFullPaymentPlanModal } = props
  const paymentItemRef = useRef(null)
  const { isOverflowed } = useOverflowed(paymentItemRef)

  const showMobilePaymentPlanButton = paymentItems.length > 3

  const paymentPlanClasses = classNames('elo-payment-plan', {
    'elo-payment-plan--overflowed': isOverflowed,
    'elo-payment-plan--mobile-overflowed': showMobilePaymentPlanButton && !showFullPaymentPlanModal,
    'elo-payment-plan--in-modal': showFullPaymentPlanModal,
  })

  return (
    <>
      <div className={paymentPlanClasses} ref={paymentItemRef}>
        {paymentItems.map((item, index) => {
          const isLastItem = index === paymentItems.length - 1

          const itemClasses = classNames('elo-payment-plan__item', {
            'elo-payment-plan__item--full-width': !item.thinItem && !showFullPaymentPlanModal,
            'elo-payment-plan__item--hidden': index > 2 && !showFullPaymentPlanModal,
          })

          return (
            <div key={index} className={itemClasses}>
              <EloPaymentItem
                variant={item.variant}
                paymentName={item.paymentName}
                price={item.price}
                oldPrice={item.oldPrice}
                netPrice={item.netPrice}
                from={item.from}
                fromLabel={item.fromLabel}
                on={item.on}
                onLabel={item.onLabel}
                to={item.to}
                toLabel={item.toLabel}
                supportiveText={item.supportiveText}
                firstItem={index === 0}
                lastItem={isLastItem}
                paymentColor={paymentColor}
              />
            </div>
          )
        })}

        {!showFullPaymentPlanModal && showMobilePaymentPlanButton && (
          <div className='elo-payment-plan__mobile-plan-button'>
            <EloLink onClick={() => toggleFullPaymentPlanModal()}>
              {I18n.t('react.shared.pricing_plan.full_payment_plan')}
            </EloLink>
          </div>
        )}
      </div>

      {isOverflowed && (
        <div className='elo-payment-plan__plan-button'>
          <EloLink onClick={() => toggleFullPaymentPlanModal()}>
            {I18n.t('react.shared.pricing_plan.full_payment_plan')}
          </EloLink>
        </div>
      )}
    </>
  )
}
