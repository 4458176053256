import { PAYMENT_PLANS } from '@elo-kit/constants/pricingPlans.constants'
import { PRODUCT_TYPE_IDS } from '../constants/products.constants'

import { checkIsHtml, parseTextFromHtml } from './froala.utils'

interface Product {
  form: string
  tickets: Ticket[]
  pricingPlans: PricingPlan[]
  free: boolean
  covers: Cover[]
}
interface Ticket {
  pricingPlans: PricingPlan[]
}
interface PricingPlan {
  currencyKey: number
  currencyId: number
  prefs: Record<string, string>
}
interface Cover {
  file: Record<string, string>
}

export const cutDescription = (description?: string, length = 200) => {
  const descriptionText = checkIsHtml(description || '') ? parseTextFromHtml(description || '') : description || ''

  return descriptionText.length > length ? `${descriptionText.substring(0, length)}...` : descriptionText
}

const isEvent = (product: { form?: string }) => product?.form === PRODUCT_TYPE_IDS.eventTickets

export const pricingPlanExists = (aProduct: Product) => {
  if (isEvent(aProduct)) {
    return (
      aProduct.tickets &&
      aProduct.tickets.length &&
      aProduct.tickets[0].pricingPlans &&
      aProduct.tickets[0].pricingPlans.length
    )
  }

  return aProduct && aProduct.pricingPlans && aProduct.pricingPlans.length
}

export const getCurrencyKeyFromProduct = (aProduct: Product) => {
  if (!pricingPlanExists(aProduct)) {
    return ''
  }

  const { currencyKey, currencyId } = isEvent(aProduct) ? aProduct.tickets[0].pricingPlans[0] : aProduct.pricingPlans[0]

  return currencyKey || currencyId
}

export const extractPriceFromProduct =
  (...pricePropNames: string[]) =>
  (aProduct: Product) => {
    if (!pricingPlanExists(aProduct) || aProduct.free) {
      return 0
    }

    const prefs = isEvent(aProduct) ? aProduct.tickets[0].pricingPlans[0].prefs : aProduct.pricingPlans[0].prefs

    if (pricePropNames.some(Boolean)) {
      return pricePropNames.map((prop) => prefs[prop]).filter(Boolean)[0]
    }

    return 0
  }

export const getOldProductPrice = extractPriceFromProduct('oldPrice', 'oldFirstAmount')

export const getProductPrice = extractPriceFromProduct('price', 'firstAmount', 'rate1Amount')

export const getFirstCoverUrl = (aProduct: Product, resolution = 's100') => {
  if (!(aProduct && aProduct.covers && aProduct.covers.length)) {
    return ''
  }

  return aProduct.covers[0].file && aProduct.covers[0].file[resolution]
}

export const getPricingPlan = (aProduct: Product): object | PricingPlan => {
  if (!pricingPlanExists(aProduct) || aProduct.free) {
    return {}
  }

  const plan = isEvent(aProduct) ? aProduct.tickets[0].pricingPlans[0] : aProduct.pricingPlans[0]

  return plan || {}
}

export const totalPlanAmount = (plan: any) => {
  if (plan?.prefs?.customIntervals) {
    let total = 0
    if (plan?.prefs?.paymentsCount > 1) {
      // prettier-ignore
      ;[...Array(parseInt(String(plan?.prefs?.paymentsCount), 10))].forEach((e, i) => {
        const { prefs = {} } = plan
        const prefsProperty = `rate${i + 1}Amount`
        total += parseFloat(plan?.prefs?.[prefsProperty as keyof typeof prefs] || '')
      })
      return total
    }
    return 0
  }
  if (plan?.form === PAYMENT_PLANS.installment) {
    return (
      parseFloat(plan?.prefs?.price || plan?.prefs?.firstAmount || 0) +
      parseFloat(plan?.prefs?.nextAmount || 0) * (parseInt(String(plan?.prefs?.paymentsCount || 0), 10) - 1)
    )
  }
  return (
    parseFloat(plan?.prefs?.firstAmount || '') +
    parseFloat(plan?.prefs?.nextAmount || '') * (parseInt(String(plan?.prefs?.paymentsCount), 10) - 1)
  )
}
