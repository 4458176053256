export const SPLITTED_PAYMENT_PLANS = {
  limitedSubscription: 'limited_subscription',
  installment: 'installment',
}

export const PAYMENT_PLANS = {
  oneTime: 'one_time',
  subscription: 'subscription',
  ...SPLITTED_PAYMENT_PLANS,
}

export const getNextChargesInfoLabel = (data) => ({
  installment: I18n.t('react.shared.pricing_plan.next_charges_info.installment', data),
  limited_subscription: I18n.t('react.shared.pricing_plan.next_charges_info.limited_subscription', data),
  subscription: I18n.t('react.shared.pricing_plan.next_charges_info.subscription', data),
})
