import React, { useState, useEffect } from 'react'

export const useOverflowed = (ref: React.RefObject<HTMLElement> | null) => {
  const [isOverflowed, setIsOverflow] = useState(false)

  useEffect(() => {
    setIsOverflow(ref?.current?.scrollWidth > ref?.current?.clientWidth)
  }, [])

  return { isOverflowed }
}
