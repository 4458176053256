import { POSITIONS } from '../constants/general.constants'

type Alignment = 'flex-end' | 'flex-start' | 'center'

export const getBackgroundImageCss = (imageUrl: string): string | 'none' => (imageUrl ? `url(${imageUrl})` : 'none')

export const hex2rgb = (hex = '#000', opacity?: number): string => {
  let h: RegExpMatchArray | null | number | string = hex.replace('#', '')
  h = h.match(new RegExp(`(.{${h.length / 3}})`, 'g'))

  if (h) {
    for (let i = 0; i < h.length; i++) {
      h[i] = String(parseInt(h[i].length === 1 ? h[i] + h[i] : h[i], 16))
    }

    if (typeof opacity !== 'undefined' && parseFloat(String(opacity))) {
      h.push(String(opacity))
    }

    return `rgba(${h.join(',')})`
  }

  return 'rgba(0, 0, 0)'
}

export const getAlignment = (value: keyof typeof POSITIONS): Alignment => {
  switch (value) {
    case POSITIONS.right:
    case POSITIONS.bottom:
      return 'flex-end'
    case POSITIONS.top:
    case POSITIONS.left:
      return 'flex-start'
    case POSITIONS.center:
    default:
      return 'center'
  }
}

export const getPosition = (position: keyof typeof POSITIONS, length: number, bottomOffset: number): string => {
  let resultPosition = 0

  switch (position) {
    case POSITIONS.bottom:
    case POSITIONS.right: {
      resultPosition = 100 - length
      break
    }
    case POSITIONS.center: {
      resultPosition = 50 - length / 2
      break
    }
    default:
      break
  }

  if (bottomOffset && position === POSITIONS.bottom) {
    resultPosition -= bottomOffset
  }

  return `${resultPosition < 0 ? 0 : resultPosition}%`
}
