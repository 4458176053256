import { Nullable } from 'types/helpers'

export const COUPON_PRODUCTS = {
  all: 'all_products',
  single: 'single_product',
  multiple: 'multiple_products',
}

export interface CouponData {
  additionalFees?: boolean
  applyForm?: string
  couponsProducts?: {
    pricingPlanId: Nullable<number>
    ticketId: Nullable<number>
    productId: Nullable<number>
  }[]
  maxCount?: Nullable<number>
  percents?: boolean
  recurring?: boolean
  upsells?: boolean
  value: string | number
}
export interface Coupon {
  form: boolean
  code: string
  data: CouponData
  applied?: boolean
}
