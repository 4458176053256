import React, { useEffect, useState } from 'react'

import { getTimeLeftObject } from 'utils/dateTime.utils'

import './countdown.scss'

interface CountdownProps {
  paymentCountdownBgColor: string
  paymentCountdownBorderColor: string
  paymentCountdownTextColor: string
  paymentCountdownTimeColor: string
  countdownText: string
  validTill: string
  ref?: object
}

export const Countdown: React.FC<CountdownProps> = (props) => {
  const {
    paymentCountdownBgColor,
    paymentCountdownBorderColor,
    paymentCountdownTextColor,
    paymentCountdownTimeColor,
    countdownText,
    validTill,
  } = props

  const [countdown, setCountdown] = useState(null)

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      const date = getTimeLeftObject(validTill || null, false, { withoutWeeks: true })

      if (date) {
        setCountdown(date)
      } else {
        setCountdown({
          weeks: 0,
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        })
        clearInterval(countdownInterval)
      }
    }, 1000)

    return () => {
      clearInterval(countdownInterval)
    }
  }, [])

  const { days, hours, minutes, seconds } = countdown || {}

  return (
    countdown && (
      <div
        className='payment-plan__countdown'
        style={{
          backgroundColor: paymentCountdownBgColor,
          border: `1px solid ${paymentCountdownBorderColor}`,
        }}
      >
        <div className='payment-plan__countdown--text' style={{ color: paymentCountdownTextColor }}>
          {countdownText || I18n.t('react.shared.pricing_plan.default_countdown_text')}
        </div>
        <div className='payment-plan__countdown--timer' style={{ color: paymentCountdownTimeColor }}>
          {!!days && (
            <>
              <div className='payment-plan__countdown--days'>
                <div className='payment-plan__countdown--day-count'>{days}</div>
                <div className='payment-plan__countdown--day-text'>
                  {days === 1 ? I18n.t('react.shared.common.day') : I18n.t('react.shared.common.days')}
                </div>
              </div>
              <div className='payment-plan__countdown--divider'>:</div>
            </>
          )}
          {!!hours && (
            <>
              <div className='payment-plan__countdown--hours'>
                <div className='payment-plan__countdown--hour-count'>{hours}</div>
                <div className='payment-plan__countdown--hour-text'>
                  {hours === 1 ? I18n.t('react.shared.common.hour') : I18n.t('react.shared.common.hours')}
                </div>
              </div>
              <div className='payment-plan__countdown--divider'>:</div>
            </>
          )}
          <>
            <div className='payment-plan__countdown--minutes'>
              <div className='payment-plan__countdown--min-count'>{minutes}</div>
              <div className='payment-plan__countdown--min-text'>{I18n.t('react.shared.common.min')}</div>
            </div>
            <div className='payment-plan__countdown--divider'>:</div>
          </>
          <div className='payment-plan__countdown--seconds'>
            <div className='payment-plan__countdown--sec-count'>{seconds}</div>
            <div className='payment-plan__countdown--sec-text'>{I18n.t('react.shared.common.sec')}</div>
          </div>
        </div>
      </div>
    )
  )
}
