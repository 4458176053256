import { ORDER_TYPES } from 'constants/ordersShared.constants'

export * from './ordersShared.constants'

export const ORDERS_FILTER_LIST = [
  'activatedAtTime',
  'campaignId',
  'chargesCountRange',
  'hideFree',
  'multiAffiliateProgram',
  'multiCouponId',
  'multiCurrencyId',
  'multiFunnelId',
  'multiFunnelNodeId',
  'multiOrderPaymentForm',
  'multiPaymentState',
  'multiPeriodType',
  'multiProductId',
  'multiProductCategory',
  'multiPublisherId',
  'multiTicketId',
  'multiPayerId',
  'multiProductIntName',
  'orderCreatedAtTime',
  'orderWithWaiting',
  'successTransfer',
  'orderExcludedNotCompleted',
  'multiPricingPlanId',
  'multiOrderTypes',
]

export const ORDERS_TABS = [
  {
    key: 'orders',
    link: '/cabinet/orders',
    label: I18n.t('react.shared.nav.orders'),
  },
  {
    key: 'order_rates',
    link: '/cabinet/order_rates',
    label: I18n.t('react.shared.nav.order_rates'),
  },
  {
    key: 'transfers',
    link: '/cabinet/transfers',
    label: I18n.t('react.common.transfers'),
  },
  {
    key: 'invoices',
    link: '/cabinet/invoices',
    label: I18n.t('react.cabinet.common.invoices_reversals'),
  },
]

export const EXPAND_ORDER_DETAILS_PARAMS = [
  'overdue',
  'test_period_passed_days_ago',
  'with_test_period',
  'test_period_passed',
  'total_amount',
  'paid_amount',
  'unpaid_amount',
  'debt_amount',
  'seller',
]

export const EXPAND_ORDER_PARAMS = [
  'product',
  'publisher.user_profile',
  'deal.incentive_plan',
  'sellables.sellable',
  'sellables.sellable_items.product.goods.digital',
  'credit_card',
  'payer.user_profile',
  'funnel',
  'affiliate_program',
  'sellables.sellable_items.ticket',
  'sellables.sellable_items.course_session',
  'sellables.sellable_items.ticket_date',
  'sellables.sellable_items.payment_tickets',
  'sellables.pricing_plan',
  'sellables.sellable_items.product.covers',
  'cancellation_term',
  'invoices',
  'minimum_terms_data',
  'transfers',
  'claim_data',
  'dunning_attributes',
  ...EXPAND_ORDER_DETAILS_PARAMS,
]

export const EXPAND_LIST_PARAMS = [
  'product.seller',
  'product.covers',
  'owner.user',
  'owner.user_profile',
  'test_period_passed_days_ago',
  'with_test_period',
  'paid_amount',
  'test_period_passed',
  'pricing_plan_ids',
  'cancellable',
  'transfers',
]

export const PAYER_ORDERS_FILTER_LIST = [
  'createdAt',
  'chargesCount',
  'paymentForm',
  'multiPaymentState',
  'periodType',
  'cancellable',
]

export const SELECT_INTERVAL_OPTIONS = {
  d: 'Days',
  w: 'Weeks',
  m: 'Months',
  y: 'Years',
}

export const PAYMENT_STATE_COLORS = {
  active: {
    color: '#39AA75',
    highlight: false,
  },
  successful: {
    color: '#39AA75',
    highlight: false,
  },
  error: {
    color: '#FF4747',
    highlight: true,
  },
  expired: {
    color: '#FF4747',
    highlight: true,
  },
  out_of_duration: {
    color: '#39AA75',
    highlight: false,
  },
  paid: {
    color: '#39AA75',
    highlight: false,
  },
  payed: {
    color: '#39AA75',
    highlight: false,
  },
  paying: {
    color: '#39AA75',
    highlight: false,
  },
  payment_canceled: {
    color: '#FFB000',
    highlight: true,
  },
  payment_not_completed: {
    color: '#FF4747',
    highlight: true,
  },
  payment_paused: {
    color: '#FF4747',
    highlight: true,
  },
  payment_pending: {
    color: '#FFB000',
    highlight: false,
  },
  payment_waiting: {
    color: '#FFB000',
    highlight: false,
  },
  payment_refunded: {
    color: '#FFB000',
    highlight: true,
  },
  publish_canceled: {
    color: '#FF4747',
    highlight: true,
  },
  publish_paused: {
    color: '#FF4747',
    highlight: true,
  },
  publish_pending: {
    color: '#FFB000',
    highlight: false,
  },
  published: {
    color: '#39AA75',
    highlight: false,
  },
  publishing: {
    color: '#39AA75',
    highlight: false,
  },
}

export const SELLER_ORDER_CSV_PARAMS = [
  {
    label: I18n.t('react.cabinet.common.token'),
    csvKey: 'token',
  },
  {
    label: I18n.t('react.cabinet.common.product'),
    csvKey: 'product',
  },
  {
    label: I18n.t('shared.common.created'),
    csvKey: 'created_at',
  },
  {
    label: I18n.t('react.cabinet.common.paymentState'),
    sortingKey: 'payment_state',
  },
  {
    label: I18n.t('react.shared.orders.csv.method'),
    sortingKey: 'payment_form',
  },
  {
    label: I18n.t('react.cabinet.common.plan'),
    sortingKey: 'period_type',
  },
  {
    label: I18n.t('react.cabinet.common.testPeriod'),
    csvKey: 'test_period',
  },
  {
    label: I18n.t('react.shared.orders.csv.paid_count'),
    csvKey: 'paid_count',
  },
  {
    label: I18n.t('react.cabinet.common.paid'),
    csvKey: 'paid',
  },
  {
    label: I18n.t('react.shared.orders.csv.product_id'),
    csvKey: 'product_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.ps'),
    csvKey: 'power_seller',
  },
  {
    label: I18n.t('react.shared.transfers.csv.inst'),
    csvKey: 'total',
  },
  {
    label: I18n.t('react.shared.transfers.csv.currency'),
    csvKey: 'currency_key',
  },
  {
    label: I18n.t('react.shared.transfers.csv.seller_business'),
    csvKey: 'seller_business',
  },
  {
    label: I18n.t('react.shared.transfers.csv.recurring'),
    csvKey: 'recurring',
  },
  {
    label: I18n.t('react.shared.transfers.csv.pricing_plan_name'),
    sortingKey: 'pricing_plan_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.pricing_plan_id'),
    csvKey: 'pricing_plan_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.coupon_code'),
    csvKey: 'coupon_code',
  },
  {
    label: I18n.t('react.shared.transfers.csv.campaign_id'),
    csvKey: 'campaign_id',
  },
  {
    label: I18n.t('react.cabinet.orders.withTestPeriod'),
    csvKey: 'with_test_period',
  },
  {
    label: I18n.t('react.shared.orders.csv.with_custom_start'),
    csvKey: 'with_custom_start',
  },
  {
    label: I18n.t('react.shared.transfers.csv.product_name'),
    csvKey: 'product_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.event_name'),
    csvKey: 'event_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.location_short'),
    csvKey: 'location_short',
  },
  {
    label: I18n.t('react.shared.transfers.csv.event_date'),
    csvKey: 'event_date',
  },
  {
    label: I18n.t('react.shared.transfers.csv.tickets_count'),
    csvKey: 'tickets_count',
  },
  {
    label: I18n.t('react.shared.transfers.csv.ticket_code'),
    csvKey: 'ticket_code',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_first_name'),
    csvKey: 'buyer_first_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_last_name'),
    csvKey: 'buyer_last_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_email'),
    csvKey: 'buyer_email',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_phone'),
    csvKey: 'buyer_phone',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_country'),
    csvKey: 'buyer_country',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_city'),
    csvKey: 'buyer_city',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_street'),
    csvKey: 'buyer_street',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_street_number'),
    csvKey: 'buyer_street_number',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_zip'),
    csvKey: 'buyer_zip',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_company'),
    csvKey: 'buyer_company',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_vat_no'),
    csvKey: 'buyer_vat_no',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_name'),
    csvKey: 'owner_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_email'),
    csvKey: 'owner_email',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_phone'),
    csvKey: 'owner_phone',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_country'),
    csvKey: 'owner_country',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_city'),
    csvKey: 'owner_city',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_street'),
    csvKey: 'owner_street',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_street_number'),
    csvKey: 'owner_street_number',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_zip'),
    csvKey: 'owner_zip',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_company'),
    csvKey: 'owner_company',
  },
  {
    label: I18n.t('react.cabinet.common.teamMember.name'),
    csvKey: 'team_member',
  },
  {
    label: I18n.t('react.shared.transfers.csv.publisher_id'),
    csvKey: 'publisher_id',
  },
  {
    label: I18n.t('react.cabinet.common.last_due_date'),
    csvKey: 'last_due_date',
  },
  {
    label: I18n.t('react.cabinet.common.due_date'),
    csvKey: 'due_date',
  },
  {
    label: I18n.t('react.cabinet.common.order_id'),
    csvKey: 'id',
  },
  {
    label: I18n.t('react.cabinet.orders.order_type'),
    csvKey: 'upsell_type',
  },
  {
    label: I18n.t('react.shared.transfers.csv.product_category'),
    csvKey: 'product_groups',
  },
  {
    label: I18n.t('react.cabinet.product.form.internal_name'),
    csvKey: 'internal_name',
  },
  {
    label: I18n.t('react.cabinet.orders.csv.buyer_additional_address'),
    csvKey: 'buyer_additional_address',
  },
  {
    label: I18n.t('react.cabinet.orders.csv.owner_additional_address'),
    csvKey: 'owner_additional_address',
  },
  {
    label: I18n.t('react.cabinet.common.cost_center'),
    csvKey: 'cost_center',
  },
  {
    label: I18n.t('react.cabinet.orders.csv.upgrade_link'),
    csvKey: 'success_page_url',
  },
]

export const SELLER_ORDER_CSV_PARAMS_WITH_SALES_TEAM = [
  {
    label: I18n.t('react.cabinet.common.token'),
    csvKey: 'token',
  },
  {
    label: I18n.t('react.cabinet.common.product'),
    csvKey: 'product',
  },
  {
    label: I18n.t('shared.common.created'),
    csvKey: 'created_at',
  },
  {
    label: I18n.t('react.cabinet.common.paymentState'),
    sortingKey: 'payment_state',
  },
  {
    label: I18n.t('react.shared.orders.csv.method'),
    sortingKey: 'payment_form',
  },
  {
    label: I18n.t('react.cabinet.common.plan'),
    sortingKey: 'period_type',
  },
  {
    label: I18n.t('react.cabinet.common.testPeriod'),
    csvKey: 'test_period',
  },
  {
    label: I18n.t('react.shared.orders.csv.paid_count'),
    csvKey: 'paid_count',
  },
  {
    label: I18n.t('react.cabinet.common.paid'),
    csvKey: 'paid',
  },
  {
    label: I18n.t('react.shared.orders.csv.product_id'),
    csvKey: 'product_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.ps'),
    csvKey: 'power_seller',
  },
  {
    label: I18n.t('react.shared.transfers.csv.inst'),
    csvKey: 'total',
  },
  {
    label: I18n.t('react.shared.transfers.csv.currency'),
    csvKey: 'currency_key',
  },
  {
    label: I18n.t('react.shared.transfers.csv.seller_business'),
    csvKey: 'seller_business',
  },
  {
    label: I18n.t('react.shared.transfers.csv.recurring'),
    csvKey: 'recurring',
  },
  {
    label: I18n.t('react.shared.transfers.csv.pricing_plan_name'),
    sortingKey: 'pricing_plan_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.pricing_plan_id'),
    csvKey: 'pricing_plan_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.coupon_code'),
    csvKey: 'coupon_code',
  },
  {
    label: I18n.t('react.shared.transfers.csv.campaign_id'),
    csvKey: 'campaign_id',
  },
  {
    label: I18n.t('react.cabinet.orders.withTestPeriod'),
    csvKey: 'with_test_period',
  },
  {
    label: I18n.t('react.shared.orders.csv.with_custom_start'),
    csvKey: 'with_custom_start',
  },
  {
    label: I18n.t('react.shared.transfers.csv.product_name'),
    csvKey: 'product_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.event_name'),
    csvKey: 'event_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.location_short'),
    csvKey: 'location_short',
  },
  {
    label: I18n.t('react.shared.transfers.csv.event_date'),
    csvKey: 'event_date',
  },
  {
    label: I18n.t('react.shared.transfers.csv.tickets_count'),
    csvKey: 'tickets_count',
  },
  {
    label: I18n.t('react.shared.transfers.csv.ticket_code'),
    csvKey: 'ticket_code',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_first_name'),
    csvKey: 'buyer_first_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_last_name'),
    csvKey: 'buyer_last_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_email'),
    csvKey: 'buyer_email',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_phone'),
    csvKey: 'buyer_phone',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_country'),
    csvKey: 'buyer_country',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_city'),
    csvKey: 'buyer_city',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_street'),
    csvKey: 'buyer_street',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_street_number'),
    csvKey: 'buyer_street_number',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_zip'),
    csvKey: 'buyer_zip',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_company'),
    csvKey: 'buyer_company',
  },
  {
    label: I18n.t('react.shared.transfers.csv.buyer_vat_no'),
    csvKey: 'buyer_vat_no',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_name'),
    csvKey: 'owner_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_email'),
    csvKey: 'owner_email',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_phone'),
    csvKey: 'owner_phone',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_country'),
    csvKey: 'owner_country',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_city'),
    csvKey: 'owner_city',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_street'),
    csvKey: 'owner_street',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_street_number'),
    csvKey: 'owner_street_number',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_zip'),
    csvKey: 'owner_zip',
  },
  {
    label: I18n.t('react.shared.transfers.csv.owner_company'),
    csvKey: 'owner_company',
  },
  {
    label: I18n.t('react.cabinet.common.teamMember.name'),
    csvKey: 'team_member',
  },
  {
    label: I18n.t('react.shared.transfers.csv.sales_member_name'),
    csvKey: 'sales_team_member_name',
  },
  {
    label: I18n.t('react.shared.transfers.csv.sales_member_id'),
    csvKey: 'sales_team_member_id',
  },
  {
    label: I18n.t('react.shared.transfers.csv.sales_member_fee'),
    csvKey: 'sales_team_member_fee',
  },
  {
    label: I18n.t('react.shared.transfers.csv.publisher_id'),
    csvKey: 'publisher_id',
  },
  {
    label: I18n.t('react.cabinet.common.last_due_date'),
    csvKey: 'last_due_date',
  },
  {
    label: I18n.t('react.cabinet.common.due_date'),
    csvKey: 'due_date',
  },
  {
    label: I18n.t('react.cabinet.common.order_id'),
    csvKey: 'id',
  },
  {
    label: I18n.t('react.cabinet.orders.order_type'),
    csvKey: 'upsell_type',
  },
  {
    label: I18n.t('react.shared.transfers.csv.product_category'),
    csvKey: 'product_groups',
  },
  {
    label: I18n.t('react.cabinet.product.form.internal_name'),
    csvKey: 'internal_name',
  },
  {
    label: I18n.t('react.cabinet.orders.csv.buyer_additional_address'),
    csvKey: 'buyer_additional_address',
  },
  {
    label: I18n.t('react.cabinet.orders.csv.owner_additional_address'),
    csvKey: 'owner_additional_address',
  },
  {
    label: I18n.t('react.cabinet.common.cost_center'),
    csvKey: 'cost_center',
  },
  {
    label: I18n.t('react.cabinet.orders.csv.upgrade_link'),
    csvKey: 'success_page_url',
  },
]

export const PAYER_ORDER_CSV_PARAMS = [
  {
    label: I18n.t('react.cabinet.common.product'),
    csvKey: 'product',
  },
  {
    label: I18n.t('shared.common.created'),
    csvKey: 'created_at',
  },
  {
    label: I18n.t('react.cabinet.common.paymentState'),
    sortingKey: 'payment_state',
  },
  {
    label: I18n.t('react.shared.orders.csv.method'),
    sortingKey: 'payment_form',
  },
  {
    label: I18n.t('react.cabinet.common.plan'),
    sortingKey: 'period_type',
  },
  {
    label: I18n.t('react.cabinet.common.testPeriod'),
    csvKey: 'test_period',
  },
  {
    label: I18n.t('react.shared.orders.csv.paid_count'),
    csvKey: 'paid_count',
  },
  {
    label: I18n.t('react.cabinet.common.last_due_date'),
    csvKey: 'last_due_date',
  },
  {
    label: I18n.t('shared.common.created'),
    csvKey: 'due_date',
  },
]

export const PUBLISHER_ORDER_CSV_PARAMS = [
  {
    label: I18n.t('react.cabinet.common.product'),
    csvKey: 'product',
  },
  {
    label: I18n.t('shared.common.created'),
    csvKey: 'created_at',
  },
  {
    label: I18n.t('react.cabinet.common.paymentState'),
    sortingKey: 'payment_state',
  },
  {
    label: I18n.t('react.shared.orders.csv.method'),
    sortingKey: 'payment_form',
  },
  {
    label: I18n.t('react.shared.transfers.csv.pricing_plan_name'),
    sortingKey: 'period_type',
  },
  {
    label: I18n.t('react.cabinet.common.payments_count'),
    csvKey: 'paid_count',
  },
  {
    label: I18n.t('react.cabinet.common.testPeriod'),
    csvKey: 'test_period',
  },
  {
    label: I18n.t('react.cabinet.common.state'),
    csvKey: 'state',
  },
  {
    label: I18n.t('react.cabinet.common.paid'),
    csvKey: 'paid',
  },
]

export const OVERVIEW_SUMMARY_KEYS = {
  totalAmount: 'totalAmount',
  vatAmount: 'vatAmount',
  paidAmount: 'paidAmount',
  unpaidAmount: 'unpaidAmount',
  debtAmount: 'debtAmount',
}

export const ORDER_TYPES_LOCALES = {
  [ORDER_TYPES.standard]: I18n.t('react.cabinet.orders.order_type_standard'),
  [ORDER_TYPES.bundle]: I18n.t('react.cabinet.orders.order_type_bundle'),
  [ORDER_TYPES.bump]: I18n.t('react.cabinet.orders.order_type_bump'),
}
