import { getPaymentPeriodLabels } from '@elo-kit/constants/paymentSetting.constants'

export const getPricingPlanName = (pricingPlanProperties: {
  form: string
  name: string
  pricingPlan: any // TODO:
}): string =>
  pricingPlanProperties.pricingPlan[`name${I18n.locale.replace(/\b\w/g, (l) => l.toUpperCase())}`] ||
  pricingPlanProperties.name ||
  getPaymentPeriodLabels()[pricingPlanProperties.form]

export const getPricingPlanDescription = (pricingPlanProperties: {
  description: string
  pricingPlan: any // TODO:
}): string =>
  pricingPlanProperties?.pricingPlan[`description${I18n.locale.replace(/\b\w/g, (l) => l.toUpperCase())}`] ||
  pricingPlanProperties?.description
